import { useContext, useState } from 'react';
import { format } from 'date-fns';
import Pencil from '@/components/icons/Pencil';
import * as Tab from '@/presentation/Workspace.Tab.Pane';
import { FormButtons } from '@/presentation/FormButtons';
import { Input } from '@/components/Input';
import styles from './style/Tab.Details.css';

export const EditTitle = ({ onCancel, onSubmit, value, disabled }: RenameProps) => {
  const [title, setTitle] = useState(value);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
  };

  return (
    <>
      <div className={styles.textboxValue}>
        <Input
            autoComplete="off"
            className={styles.input}
            name='title'
            value={title}
            onChange={onChange}
            placeholder='Transcript' />
        <FormButtons
            className={styles.btns}
            size={16}
            disabled={disabled || value.trim().length < 1}
            onCancel={onCancel}
            onSubmit={() => onSubmit(title)} />
      </div>
    </>
  );
};

type RenameProps = {
  onCancel: () => void;
  onSubmit: (title: string) => void;
  value: string;
  disabled: boolean;
};